<!--
 * @Author: xr
 * @Date: 2022-02-17 14:11:12
 * @LastEditors: xr
 * @LastEditTime: 2022-03-03 14:32:16
 * @FilePath: \postStation-office\src\views\customService.vue
-->
<template>
<div class="main">
  <div class="main_box">
    <a-row style="padding: 0px 10px;">
       <!-- <div id="container"/> -->
    </a-row>
    <a-row style="padding: 10px;">
      <img src="../assets/img/customService.png" style="width:36px;margin-left:40px;" alt="" srcset="">
      <span style="font-size: 20px;line-height:36px;vertical-align: middle;margin-left:6px;">客服中心</span>
      <a-divider dashed/>
      <a-row  :gutter="20" class="service-box" >
        <a-empty v-if="stationData.length===0" class="empty-data"/>
        <a-col v-else :span="12" v-for="(item,index) in stationData" :key="index" style="padding:10px">
          <a-row class="service-item">

            <p><a-icon type="home" />{{item.stationName}}</p>
          </a-row>
          <a-row class="service-item">
            <img src="../assets/img/place.png" alt="">
            <span>地址:</span>
            <span >{{item.address}}</span>
          </a-row>
           <a-row class="service-item">
            <img src="../assets/img/human.png" alt="">
            <span>联系人:</span>
            <span>{{item.contacts}}</span>
          </a-row>
          <a-row class="service-item">
            <img src="../assets/img/phone.png" alt="">
            <span>电话:</span>
            <span>{{item.contactNumber}}</span>
          </a-row>
          <a-divider />
        </a-col>
      </a-row>
    </a-row>
  </div>
</div>
</template>
<script>
import { getStation } from '@/api/index'
export default {
  name: 'CustomService',
  data() {
    return {
      // 驿站数据
      stationData: []
    }
  },
  methods: {},
  created() {
    getStation().then(res => {
      this.stationData = res.result
    })
  }

}
</script>
<style lang="scss" scoped>
.service-box{
  padding: 20px 40px;

  .service-item{
    p{
      font-size: 22px;
      margin-bottom:8px;
      color:#2e6191;
    }
    img{
      width:20px;
    }
    span{
      font-size:16px;
      margin-left: 8px;
      vertical-align: middle;
    }
  }
}
</style>
